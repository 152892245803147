import find from 'lodash/find'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import truncate from 'lodash/truncate'
import findIndex from 'lodash/findIndex'
import min from 'lodash/min'
import chunk from 'lodash/chunk'

import {
  updateAllInArray,
  updateInArray,
  updateSelectedInArray
} from 'helpers/arrays'
import { numberOfDaysXMonthsFromNow } from 'helpers/date'
import { scrollToElement } from 'helpers/scroll'
import {
  findCategory,
  findProductCategory,
  formatCategories,
  formatCategory,
  getProductsToAdd,
  searchQueryForRecommendedProducts,
  shouldReloadAllCategories,
  shouldReloadCategory,
  shouldShowSampleCategory,
  showMoreProductsInCategory,
  validateCategory,
  visibleCategory
} from 'helpers/tracker'
import { getCalculatedCategory } from 'helpers/categories'

import { PRODUCT_TRACKER_GROUP_CREATED } from 'constants/activation_points/tracker'

import * as FormatTrackerChart from 'services/format_tracker_chart'
import Api from 'services/api'
import { trackPendoEvent } from 'services/pendo'
import {
  getProductForSegment,
  sendAddProductToTrackEvent,
  sendSegmentSearchResultViewedEvent
} from 'helpers/segment'
import { SEGMENT_PAGE_TRACK_ROUTS_MAPPING } from 'constants/routes'
import { sendSegmentTrackEvent, safeSegmentCall } from 'services/segment'
import {
  SELECT_GRAPH_DATE_RANGE,
  SET_GRAPH_ACCESSORS,
  TOGGLE_GRAPH_Y_AXIS
} from 'constants/action_types/tracker/side_panel'
import { GROUP_NAME_CHANGED } from 'constants/segment'
import {
  ADD_PENDING_TRACKS,
  REMOVE_PENDING_TRACKS
} from 'constants/action_types/tracker/tracking'
import { textFromHtml } from 'helpers/strings'
import * as type from 'constants/action_types'
import { createNotification } from '../notifications'
import { addProductAlert, batchAddProductAlerts } from '../product_alerts'

const PRODUCT_TRACKS_BATCH_SIZE = 100

/**
 *
 * Base Tracker Actions
 *
 */

/**
 * Function used to evaluate which message should be shown when adding a product to the tracker.
 * Currently, we use 'Uncategorized' in places where you can add the product but there's no category attached (Ex.: share of voice => top asins and CSV export)
 * On those edge cases, we can't show the category in the message.
 */

const getMessage = ({ categoryName, productTrackIds, alreadyTracked }) => {
  const hasCategory = categoryName !== 'Uncategorized'

  const categoryMessage = {
    default: `Successfully added ${productTrackIds.length} new products to "${categoryName}"`,
    alreadyTracked: `Successfully added ${productTrackIds.length} new products to "${categoryName}". ${alreadyTracked} ASINs already being tracked`
  }

  const noCategoryMessage = {
    default: `Successfully added ${productTrackIds.length} new products`,
    alreadyTracked: `Successfully added ${productTrackIds.length} new products. ${alreadyTracked} ASINs already being tracked`
  }

  return hasCategory ? categoryMessage : noCategoryMessage
}

export const getProductTracks = () => async (dispatch, getState) => {
  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      isLoading: true
    }
  })
  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'loadTracks'
  })

  const {
    globalData: { allTrackedProducts },
    tracker: { selectedMonths }
  } = getState()

  const selectedDays = numberOfDaysXMonthsFromNow(selectedMonths)
  const res = await Api.getTrackerCategories()

  if (res.ok) {
    // trackedProducts is actually an array of categories
    const trackedProducts = formatCategories(res.data)

    if (shouldShowSampleCategory(allTrackedProducts.length, trackedProducts)) {
      dispatch(switchTab('Sample Products'))
    }

    await dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        trackedProducts
      }
    })

    if (getState().tracker.activeTab === 'Overview') {
      dispatch(loadAllCategories(selectedDays))
    }

    dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        isLoading: false
      }
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Load Error!'
      })
    )
  }

  dispatch({
    type: type.RESET_TRACKER_ACTION,
    payload: 'loadTracks'
  })
}

export const toggleReactivateFromTracker = show => dispatch => {
  dispatch({
    type: type.TOGGLE_REACTIVATE_FROM_TRACKER,
    payload: show
  })
}

/**
 *
 * Add to Tracker Form
 *
 */

export const trackerHandleInputChange = ({ prop, value }) => dispatch => {
  dispatch({
    type: type.TRACKER_INPUT_FIELD_UPDATE,
    payload: {
      prop,
      value
    }
  })
}

export const setCountry = value => async (dispatch, getState) => {
  if (getState().tracker.selectedCountry !== value) {
    dispatch({
      type: type.TRACKER_INPUT_FIELD_UPDATE,
      payload: {
        prop: 'selectedCountry',
        value
      }
    })
  }
}

/**
 *
 * Tracker Category Actions
 *
 */

export const switchTab = activeTab => async (dispatch, getState) => {
  dispatch({
    type: type.RESET_CHECKED_PRODUCTS
  })

  await dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      activeTab
    }
  })

  const {
    tracker,
    tracker: { trackedProducts, selectedMonths }
  } = getState()

  const selectedDays = numberOfDaysXMonthsFromNow(selectedMonths)
  const category = find(trackedProducts, {
    category_name: activeTab
  })

  if (category) {
    dispatch(setCountry(category.category_country || 'us'))
  }

  if (shouldReloadAllCategories(tracker, selectedDays)) {
    dispatch(loadAllCategories(selectedDays))
  } else if (shouldReloadCategory(tracker)) {
    const category = visibleCategory(tracker)
    dispatch(loadCategory(category.category_id))
  }
}

export const viewProductInTracker = id => dispatch => {
  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      viewTrackId: id
    }
  })

  window.location.hash = 'tracker'
}

export const searchAsinInTracker = asinSearchTerm => async (
  dispatch,
  getState
) => {
  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      asinNotFound: false
    }
  })

  if (isEmpty(asinSearchTerm)) {
    // Reset the asin search term field to an empty string
    // if it's nullified
    return dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        asinSearchTerm: '',
        checkAll: false
      }
    })
  }

  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      asinSearchTerm
    }
  })

  const { trackedProducts } = getState().tracker
  const category = findProductCategory(trackedProducts, 'asin', asinSearchTerm)

  if (category) {
    dispatch({
      type: type.PENDING_TRACKER_ACTION,
      payload: `asinSearch`
    })

    dispatch({
      type: type.RESET_CHECKED_PRODUCTS
    })

    await dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        activeTab: category.category_name
      }
    })

    dispatch({
      type: type.RESET_TRACKER_ACTION,
      payload: `asinSearch`
    })

    const { appType } = getState().globalData
    sendSegmentSearchResultViewedEvent('Product Tracker', appType)
  } else {
    dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        activeTab: 'Overview',
        asinNotFound: true
      }
    })
  }
}

export const scrollToTrack = () => async (dispatch, getState) => {
  const { trackedProducts, viewTrackId } = getState().tracker

  const category = findProductCategory(trackedProducts, 'id', viewTrackId)

  if (category) {
    dispatch({
      type: type.RESET_CHECKED_PRODUCTS
    })

    await dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        activeTab: category.category_name
      }
    })

    const index = findIndex(
      category.products,
      product => product.id === viewTrackId
    )
    const numberVisible = min([index + 3, category.products.length])

    await dispatch(showMoreProducts(numberVisible))

    scrollToElement(viewTrackId, {
      offset: -125
    })

    dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        productJustAdded: viewTrackId,
        viewTrackId: null
      }
    })

    dispatch(removeTrackerNotice())
  }
}

export const showMoreProducts = numberVisible => async (dispatch, getState) => {
  const {
    tracker,
    tracker: {
      showMore: { isLoading }
    }
  } = getState()

  if (isLoading) return

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'showMore'
  })

  const trackedProducts = showMoreProductsInCategory(tracker, numberVisible)

  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      trackedProducts
    }
  })

  dispatch({
    type: type.RESET_TRACKER_ACTION,
    payload: 'showMore'
  })
}

export const selectCategoryInDropdown = ({ value, country }) => dispatch => {
  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      selectedCountry: country || 'us',
      selectedGroup: value
    }
  })
}

export const loadAllCategories = days => (dispatch, getState) => {
  const { trackedProducts } = getState().tracker

  trackedProducts.forEach(category => {
    if (!['Ungrouped', 'Sample Products'].includes(category.category_name)) {
      dispatch(loadCategory(category.category_id))
    }
  })

  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      overviewMaxDataRange: days
    }
  })
}

export const loadCategory = id => async (dispatch, getState) => {
  const {
    tracker,
    tracker: { selectedMonths }
  } = getState()
  const category = findCategory(tracker, id)
  const selectedDays = numberOfDaysXMonthsFromNow(selectedMonths)
  if (category && category.maxDataRange >= selectedDays) return

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'loadCategory'
  })

  // Set category and each of its products to 'isLoading' state
  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      trackedProducts: updateInArray(
        getState().tracker.trackedProducts,
        {
          category_id: id
        },
        {
          isLoading: true,
          products: updateAllInArray(category.products, {
            isLoading: true
          })
        }
      )
    }
  })

  // Collect product track ids to fire off for batch scrape data
  const productTrackIds = category.product_tracks.map(
    productTrack => productTrack.id
  )
  await dispatch(batchGetProductTracks(category, productTrackIds, selectedDays))
  dispatch({
    type: type.RESET_TRACKER_ACTION,
    payload: 'loadCategory'
  })
}

export const batchGetProductTracks = (
  category,
  productTrackIds,
  selectedDays
) => async (dispatch, getState) => {
  const {
    categories: { categories }
  } = getState()

  const batches = chunk(productTrackIds, PRODUCT_TRACKS_BATCH_SIZE)

  const requests = batches.map(ids =>
    Api.getProductTracks({
      ids,
      selectedDays
    })
  )

  let products = [...category.products]
  const responses = await Promise.all(requests)

  const validResponses = responses.filter(res => res.ok)

  validResponses.forEach(res => {
    res.data.products.forEach(product => {
      // Format the category to show as the known parent categories.
      const countryCategories = categories[product.country] || []
      const calculatedCategory = getCalculatedCategory(
        countryCategories,
        product.subCategory || product.calculatedCategory
      )

      const formattedProduct = {
        ...product,
        calculatedCategory: calculatedCategory.name,
        isLoading: false,
        chartType: 'sales'
      }

      // If the category is not supported by Sales Estimator, remove the estRevenue and estimatedSales attributes
      if (!calculatedCategory.supported) {
        formattedProduct.unsupportedCategory = true
        delete formattedProduct.estRevenue
        delete formattedProduct.estimatedSales
      }

      products = updateInArray(products, { id: product.id }, formattedProduct)
    })
  })

  const payload = {
    trackedProducts: updateInArray(
      getState().tracker.trackedProducts,
      {
        category_id: category.category_id
      },
      formatCategory(
        {
          ...category,
          products
        },
        selectedDays
      )
    )
  }

  dispatch({
    type: type.SET_TRACKER_DATA,
    payload
  })
}

export const createCategory = params => async (dispatch, getState) => {
  const {
    tracker: {
      category: selectedCategory,
      selectedCountry: country,
      recommendedProducts,
      productToAdd,
      trackedProducts,
      isPrivate: isSelectedCategoryPrivate
    },
    globalData: { allTrackedProducts, appType }
  } = getState()

  const categoryName = params?.category || selectedCategory
  const countryCode = params?.country || country

  // Only testing for a falsy value from the params would give false positives/negatives
  const isPrivate =
    typeof params?.isPrivate !== 'undefined'
      ? params?.isPrivate
      : isSelectedCategoryPrivate

  if (isEmpty(categoryName)) return

  const validation = validateCategory(
    getState().tracker.trackedProducts,
    categoryName
  )

  if (!validation.ok) {
    return dispatch(
      createNotification({
        message: validation.message,
        level: 'error',
        title: 'Tracker Error:'
      })
    )
  }

  const products =
    params?.products ||
    getProductsToAdd(recommendedProducts, productToAdd, allTrackedProducts)

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'createCategory'
  })

  if (products?.length > 0) {
    // show the glass panel early, before the Category creation call
    dispatch({
      type: type.PENDING_TRACKER_ACTION,
      payload: 'addRecommendedTrack'
    })
  }

  const res = await Api.createTrackerCategory({
    name: categoryName,
    country: countryCode,
    is_private: isPrivate,
    products
  })

  if (res.ok) {
    const { category, trackerCategories } = res.data
    const locationHash = window.location.hash
    if (locationHash.includes('tracker') || !isEmpty(trackedProducts)) {
      dispatch({
        type: type.CREATE_CATEGORY,
        payload: formatCategory(category),
        stayOnCurrentTab: params?.stayOnCurrentTab || false
      })
    }

    dispatch({
      type: type.LOAD_GLOBAL_DATA,
      payload: {
        trackerCategories
      }
    })

    const page = locationHash.substr(1)
    const trackerProducts = params?.products || []
    safeSegmentCall(() => {
      sendSegmentTrackEvent('Group Created', {
        tab: SEGMENT_PAGE_TRACK_ROUTS_MAPPING[page]?.name,
        groupType: 'product',
        groupName: categoryName,
        product: getProductForSegment(appType),
        numberOfObjects: trackerProducts.length,
        products: trackerProducts.map(product => ({
          title: product.name,
          asin: product.asin,
          imageUrl: product.imageUrl
        }))
      })
    })

    if (products?.length > 0) {
      // add selected recommended products (if any) to this new category
      await dispatch(
        createProductTracks(
          {
            category: category.category_name,
            products
          },
          params?.pendoEvent,
          true
        )
      )
    } else {
      dispatch({
        type: type.SET_GLOBAL_MODAL
      })
    }

    dispatch({
      type: type.COMPLETED_TRACKER_ACTION,
      payload: 'createCategory'
    })

    if (isEmpty(products)) {
      dispatch(
        createNotification({
          message: 'Successfully added group!',
          level: 'success',
          title: 'Tracker Notice:'
        })
      )
    }

    dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        category: '',
        createNewGroupStep: 1
      }
    })

    trackPendoEvent(PRODUCT_TRACKER_GROUP_CREATED)

    setTimeout(() => {
      dispatch({
        type: type.RESET_TRACKER_ACTION,
        payload: 'createCategory'
      })
    }, 2000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )

    dispatch({
      type: type.RESET_TRACKER_ACTION,
      payload: 'createCategory'
    })
  }

  if (!isEmpty(productToAdd)) {
    dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        productToAdd: {}
      }
    })
  }
}

export const updateCategoryPrivacy = (id, name, isPrivate) => dispatch => {
  dispatch({
    type: type.EDIT_CATEGORY,
    payload: {
      id,
      props: {
        category_name: name,
        is_private: isPrivate
      }
    }
  })
}

export const editCategory = (name, id) => async (dispatch, getState) => {
  const validation = validateCategory(getState().tracker.trackedProducts, name)

  if (!validation.ok) {
    return dispatch(
      createNotification({
        message: validation.message,
        level: 'error',
        title: 'Tracker Error:'
      })
    )
  }

  dispatch({
    type: type.EDIT_CATEGORY,
    payload: {
      id,
      props: {
        category_name: name
      }
    }
  })

  const res = await Api.updateTrackerCategory({
    id,
    name
  })

  if (res.ok) {
    dispatch({
      type: type.LOAD_GLOBAL_DATA,
      payload: res.data
    })

    dispatch({
      type: type.TRACKER_INPUT_FIELD_UPDATE,
      payload: {
        prop: 'category',
        value: ''
      }
    })

    safeSegmentCall(() => {
      sendSegmentTrackEvent(GROUP_NAME_CHANGED, {
        tab: 'Product Tracker',
        groupName: name,
        groupType: 'product'
      })
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )

    setTimeout(() => window.location.reload(), 1000)
  }
}

export const deleteCategory = id => async (dispatch, getState) => {
  const category = findCategory(getState().tracker, id)
  const { products, category_name } = category
  const ids = products.map(product => product.product_id)

  dispatch({
    type: type.DELETE_CATEGORY,
    payload: id
  })

  dispatch({
    type: type.REMOVE_FROM_GLOBAL_TRACKER,
    payload: ids
  })

  const res = await Api.deleteTrackerCategory({
    id
  })

  if (res.ok) {
    dispatch({
      type: type.LOAD_GLOBAL_DATA,
      payload: res.data
    })

    const page = window.location.hash.substr(1)
    const { appType } = getState().globalData
    safeSegmentCall(() => {
      sendSegmentTrackEvent('Group Deleted', {
        tab: SEGMENT_PAGE_TRACK_ROUTS_MAPPING[page]?.name,
        groupType: 'product',
        groupName: category_name,
        numberOfObjects: products?.length,
        product: getProductForSegment(appType)
      })
    })
    sendAddProductToTrackEvent(
      category_name,
      'Product Tracker',
      appType,
      products,
      undefined,
      'bulk',
      'Product Removed'
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )

    setTimeout(() => window.location.reload(), 1000)
  }
}

export const sortCategory = ({ oldPosition, newPosition, category }) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: type.SORT_CATEGORY,
    payload: {
      oldPosition,
      newPosition,
      categoryName: category.category_name
    }
  })

  const ids = map(
    getState().tracker.trackedProducts.filter(
      category => category.category_id !== 'sample'
    ),
    'category_id'
  )

  dispatch(removeTrackerNotice())

  const res = await Api.sortCategory({
    ids
  })

  if (res.ok) {
    dispatch({
      type: type.LOAD_GLOBAL_DATA,
      payload: res.data
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )

    setTimeout(() => window.location.reload(), 1000)
  }
}

/**
 *
 * Product Track Actions
 *
 */

export const removeTrackerNotice = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: type.REMOVE_TRACKER_NOTICE
    })
  }, 4000)
}

export const loadProductTrackData = (id, categoryName) => async dispatch => {
  dispatch({
    type: type.PENDING_AVERAGES,
    payload: {
      id,
      categoryName
    }
  })

  const res = await Api.loadProductTrackData({
    id
  })

  const { data, message } = res

  if (!res.ok) {
    dispatch({
      type: type.FAILED_GET_TRACKER_CHART_DATA,
      payload: {
        id,
        categoryName
      }
    })
  } else if (res.ok && message) {
    // This scenario covers if there is no chart data, BUT there's a message to display to the user
    dispatch({
      type: type.COMPLETED_GET_TRACKER_CHART_DATA,
      payload: {
        id,
        categoryName,
        productData: {
          product: data.product,
          message
        }
      }
    })
  } else {
    dispatch({
      type: type.COMPLETED_GET_TRACKER_CHART_DATA,
      payload: {
        id,
        categoryName,
        productData: {
          storedChartData: FormatTrackerChart.run(id, data),
          product: data.product
        },
        reloadCategoryAverages: true
      }
    })
  }
}

export const createProductTrack = ({ id, country, asin, category }) => async (
  dispatch,
  getState
) => {
  const {
    globalData: {
      allTrackedProducts,
      membershipInfo: { membershipStatus, tracker_limit, hasActiveMembership },
      featureLimits: {
        alerts: { featureLimit: alertsFeatureLimit }
      }
    },
    tracker: { activeTab }
  } = getState()

  dispatch({
    type: type.PENDING_ADD_PRODUCT_TRACK,
    payload: id
  })

  if (membershipStatus === 5) {
    dispatch({
      type: type.TOGGLE_REACTIVATE_FROM_TRACKER
    })
    return dispatch({
      type: type.RESET_ADD_PRODUCT_TRACK
    })
  }

  if (allTrackedProducts.length >= parseInt(tracker_limit, 10)) {
    dispatch(
      createNotification({
        message: 'Sorry, but you have already reached your tracker limit',
        level: 'error',
        title: 'Tracker Error!'
      })
    )
    return dispatch({
      type: type.RESET_ADD_PRODUCT_TRACK
    })
  }

  if (asin.length < 10) {
    dispatch(
      createNotification({
        message: 'Sorry, but that asin is invalid. Please re-enter the asin.',
        level: 'error',
        title: 'Tracker Error!'
      })
    )
    return dispatch({
      type: type.RESET_ADD_PRODUCT_TRACK
    })
  }

  if (includes(allTrackedProducts, id)) {
    dispatch(
      createNotification({
        message: 'You are already tracking this product.',
        level: 'success',
        title: 'Tracker Alert!'
      })
    )
    return dispatch({
      type: type.RESET_ADD_PRODUCT_TRACK
    })
  }

  const categoryName = category || activeTab
  const res = await Api.createProductTrack({
    asin,
    country,
    category: categoryName
  })

  const { message, data } = res

  if (!res.ok) {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Notice:'
      })
    )
    dispatch({
      type: type.RESET_ADD_PRODUCT_TRACK
    })
  } else if (res.ok && data.variants) {
    dispatch({
      type: type.TOGGLE_VARIANT_MODAL,
      payload: data.variantData
    })
    dispatch({
      type: type.RESET_ADD_PRODUCT_TRACK
    })
  } else {
    if (hasActiveMembership && alertsFeatureLimit !== 0) {
      dispatch(addProductAlert({ id, country, asin }, false))
    }

    dispatch({
      type: type.COMPLETED_ADD_PRODUCT_TRACK
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ADD_PRODUCT_TRACK
      })
    }, 5000)

    dispatch(
      createNotification({
        message,
        level: 'success',
        title: 'Tracker Notice:'
      })
    )

    dispatch({
      type: type.ADD_TO_GLOBAL_TRACKER,
      payload: data.product_id
    })

    if (
      window.location.hash.includes('tracker') ||
      !isEmpty(getState().tracker.trackedProducts)
    ) {
      dispatch({
        type: type.ADD_PRODUCT_TO_CATEGORY,
        payload: {
          product: data,
          categoryName
        }
      })

      dispatch(removeTrackerNotice())

      dispatch({
        type: type.SET_GLOBAL_MODAL
      })

      dispatch(loadProductTrackData(data.product_id, categoryName))
    }
  }
}

export const createProductTracks = (
  { products, category } = {},
  pendoEvent = null,
  newCategory,
  niche
) => async (dispatch, getState) => {
  const {
    globalData: {
      allTrackedProducts,
      membershipInfo: { membershipStatus },
      appType
    },
    tracker: {
      category: currentCategory,
      activeTab,
      recommendedProducts,
      productToAdd
    }
  } = getState()

  products =
    products ||
    getProductsToAdd(recommendedProducts, productToAdd, allTrackedProducts)

  if (!products) return

  // TODO: Abstract this out:
  // This file is should only have knowledge of the Product Tracker. It should not
  // know how the membership system is implemented. IE: the meaning behind the
  // membership status codes
  if (membershipStatus === 5) {
    dispatch({
      type: type.TOGGLE_REACTIVATE_FROM_TRACKER
    })
    return
  }

  // prefer the category passed as param
  let categoryName = category

  // fallback to other categories
  if (!categoryName) {
    categoryName =
      currentCategory ||
      (activeTab === 'Sample Products' ? 'Uncategorized' : activeTab)
  }

  if (pendoEvent) {
    trackPendoEvent(pendoEvent)
  }

  await dispatch(
    _trackProductsAsync(products, categoryName, appType, newCategory, niche)
  )
}

const _trackProductsAsync = (
  products,
  categoryName,
  appType,
  newCategory,
  niche
) => async (dispatch, getState) => {
  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'addRecommendedTrack'
  })

  const ids = products.map(
    ({ id, country, asin }) => id || `${country}/${asin}`
  )
  const asins = products.map(product => product.asin)
  const { country } = products[0]

  dispatch({ type: ADD_PENDING_TRACKS, payload: ids })

  const combinedResponse = await Api.createProductTracksBulk({
    asins,
    category: categoryName,
    country
  })

  dispatch(processBulkActionResponse(combinedResponse, categoryName, ids))

  // close the modal in the end
  if (combinedResponse.ok) {
    let productsToAdd = products
    if (newCategory) {
      const {
        tracker: { trackedProducts }
      } = getState()

      productsToAdd =
        trackedProducts?.length &&
        trackedProducts.filter(tab => tab.category_name === categoryName)[0]
          .products
    }

    const page = window.location.hash.substr(1)

    sendAddProductToTrackEvent(
      categoryName,
      SEGMENT_PAGE_TRACK_ROUTS_MAPPING[page]?.name,
      appType,
      productsToAdd,
      niche
    )
    dispatch({
      type: type.SET_GLOBAL_MODAL
    })
  }
}

export const processBulkActionResponse = (
  combinedResponse,
  categoryName,
  requestedIds
) => async (dispatch, getState) => {
  const productTrackIds = []
  const productIds = []
  const addedProducts = []
  const productsToAlert = []
  const {
    globalData: {
      membershipInfo: { hasActiveMembership },
      featureLimits: {
        alerts: { featureLimit: alertsFeatureLimit }
      }
    }
  } = getState()

  if (combinedResponse.ok) {
    const productResponses = Object.values(combinedResponse.data)

    productResponses.forEach(next => {
      if (next.data) {
        addedProducts.push({
          ...next.data,
          checked: false
        })

        // -1 means unlimited
        // 0 means no access
        // no other value expected since this is a toggle feature limit (all or nothing)
        if (alertsFeatureLimit !== 0) {
          productsToAlert.push({
            id: next.data.id,
            country: next.data.country,
            asin: next.data.asin
          })
        }

        productIds.push(next.data.product_id)
        productTrackIds.push(next.data.product_track_id)
      } else {
        dispatch(
          createNotification({
            message: next.message,
            level: 'error',
            title: 'Tracker Notice:'
          })
        )
      }
    })
  }

  if (!isEmpty(productTrackIds)) {
    if (hasActiveMembership && productsToAlert.length) {
      dispatch(batchAddProductAlerts(productsToAlert, false))
    }

    dispatch({
      type: type.ADD_TO_GLOBAL_TRACKER,
      payload: productIds // add new product IDs to allTrackedProducts array in global state
    })

    if (
      window.location.hash.includes('tracker') ||
      !isEmpty(getState().tracker.trackedProducts)
    ) {
      dispatch({
        type: type.ADD_PRODUCT_TO_CATEGORY,
        payload: {
          product: addedProducts,
          categoryName
        }
      })
    }
  }

  if (combinedResponse.ok) {
    const alreadyTracked = requestedIds
      ? requestedIds.length - productTrackIds.length
      : 0

    const message = getMessage({
      categoryName,
      productTrackIds,
      alreadyTracked
    })

    const finalMessage =
      alreadyTracked <= 0 ? message.default : message.alreadyTracked

    dispatch(
      createNotification({
        message: finalMessage,
        level: 'success',
        title: 'Product Saved!'
      })
    )
  } else {
    dispatch(
      createNotification({
        level: 'error',
        title: 'Tracker Notice:',
        message: combinedResponse.error
      })
    )
  }

  dispatch({
    type: type.RESET_TRACKER_ACTION,
    payload: 'addRecommendedTrack'
  })

  dispatch({
    type: type.RESET_ADD_PRODUCT_TRACK
  })

  dispatch(removeTrackerNotice())

  if (requestedIds?.length > 0) {
    dispatch({ type: REMOVE_PENDING_TRACKS, payload: requestedIds })
  }

  // Get category and selected days to fire off batch get product tracks call
  // for newly added tracks
  const {
    tracker,
    tracker: { selectedMonths, trackedProducts }
  } = getState()

  const category = visibleCategory(tracker)

  if (category) {
    // Set category and each of its products to 'isLoading' state
    dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        trackedProducts: updateInArray(
          trackedProducts,
          {
            category_id: category.category_id
          },
          {
            isLoading: true,
            products: updateSelectedInArray(
              category.products,
              'product_track_id',
              productTrackIds,
              {
                isLoading: true
              }
            )
          }
        )
      }
    })
    const selectedDays = numberOfDaysXMonthsFromNow(selectedMonths)
    dispatch(batchGetProductTracks(category, productTrackIds, selectedDays))
  }
}

export const handleSetSelectedMonths = months => dispatch => {
  dispatch({
    type: type.SET_SELECTED_MONTHS,
    payload: months
  })
}

export const setGraphSelectedMonths = months => dispatch => {
  dispatch({
    type: SELECT_GRAPH_DATE_RANGE,
    payload: months
  })
}

export const toggleGraphYAxis = () => (dispatch, getState) => {
  const { graphHideYAxis } = getState().tracker

  dispatch({
    type: TOGGLE_GRAPH_Y_AXIS,
    payload: !graphHideYAxis
  })
}

export const setGraphAccessors = accessors => dispatch => {
  dispatch({
    type: SET_GRAPH_ACCESSORS,
    payload: accessors
  })
}

/**
 *
 * Delete Product Track Actions
 *
 */

export const toggleDeleteConfirmation = (data = null) => dispatch => {
  dispatch({
    type: type.TOGGLE_DELETE_CONFIRMATION,
    payload: data
  })
}

export const deleteProductTrack = product => async (dispatch, getState) => {
  const { id, product_track_id, sampleProduct } = product

  if (sampleProduct) {
    return dispatch(
      createNotification({
        message:
          "You can't delete a sample product, but you can hide the sample product tracks tab in the toolbar!",
        level: 'info',
        title: 'Tracker Notice:'
      })
    )
  }

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'deleteTrack'
  })

  const res = await Api.deleteProductTrack({
    product_track_id
  })

  if (res.ok) {
    dispatch({
      type: type.DELETE_PRODUCT_TRACK,
      payload: id
    })

    dispatch({
      type: type.DECREMENT_FEATURE_USAGE_COUNT,
      payload: {
        feature: 'productTracker'
      }
    })

    dispatch({
      type: type.REMOVE_FROM_GLOBAL_TRACKER,
      payload: id
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Tracker Notice:'
      })
    )

    dispatch({
      type: type.COMPLETED_TRACKER_ACTION,
      payload: 'deleteTrack'
    })

    setTimeout(() => {
      dispatch({
        type: type.TOGGLE_DELETE_CONFIRMATION
      })

      dispatch({
        type: type.RESET_TRACKER_ACTION,
        payload: 'deleteTrack'
      })
    }, 2000)

    const { appType } = getState().globalData
    const { activeTab } = getState().tracker

    sendAddProductToTrackEvent(
      activeTab.name,
      'Product Tracker',
      appType,
      [product],
      undefined,
      undefined,
      'Product Removed'
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )

    dispatch({
      type: type.RESET_TRACKER_ACTION,
      payload: 'deleteTrack'
    })
  }
}

export const batchDeleteProductTracks = () => async (dispatch, getState) => {
  const category = visibleCategory(getState().tracker)

  if (category.category_id === 'sample') {
    return
  }

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'deleteTrack'
  })

  const checkedProducts = filter(category.products, {
    checked: true
  })
  const ids = map(checkedProducts, 'id')

  const res = await Api.batchDeleteProductTrack({
    product_track_ids: map(checkedProducts, 'product_track_id')
  })

  if (res.ok) {
    dispatch({
      type: type.BATCH_DELETE_PRODUCT_TRACKS,
      payload: ids
    })

    dispatch({
      type: type.REMOVE_FROM_GLOBAL_TRACKER,
      payload: ids
    })

    dispatch({
      type: type.RESET_CHECKED_PRODUCTS
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Tracker Notice:'
      })
    )

    dispatch({
      type: type.COMPLETED_TRACKER_ACTION,
      payload: 'deleteTrack'
    })

    setTimeout(() => {
      dispatch({
        type: type.TOGGLE_DELETE_CONFIRMATION
      })

      dispatch({
        type: type.RESET_TRACKER_ACTION,
        payload: 'deleteTrack'
      })
    }, 2000)

    const { appType } = getState().globalData
    sendAddProductToTrackEvent(
      category.category_name,
      'Product Tracker',
      appType,
      checkedProducts,
      undefined,
      'manual',
      'Product Removed'
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )

    dispatch({
      type: type.RESET_TRACKER_ACTION,
      payload: 'deleteTrack'
    })
  }
}

export const batchCategorizeProductTracks = ({
  category_name,
  category_id
}) => async (dispatch, getState) => {
  const sourceCategory = visibleCategory(getState().tracker)

  if (sourceCategory.category_id === 'sample') {
    return
  }

  const checkedProducts = filter(sourceCategory.products, {
    checked: true
  })

  dispatch({
    type: type.BATCH_CATEGORIZE_PRODUCT_TRACKS,
    payload: {
      categoryName: category_name,
      products: checkedProducts
    }
  })

  const updatedCategory = find(getState().tracker.trackedProducts, {
    category_id
  })

  const res = await Api.batchCategorizeProductTrack({
    idsToSort: map(updatedCategory.products, 'product_track_id'),
    idsToCategorize: map(checkedProducts, 'product_track_id'),
    categoryId: category_id
  })

  if (res.ok) {
    dispatch({
      type: type.RESET_CHECKED_PRODUCTS
    })

    dispatch(removeTrackerNotice())

    dispatch(
      createNotification({
        message: `Product Tracks moved to ${category_name}!`,
        level: 'success',
        title: 'Tracker Notice:'
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )
  }
}

export const moveProductsToNewCategory = (
  categoryName,
  isPrivateCategory
) => async (dispatch, getState) => {
  const prevTrackerCategories = getState().globalData.trackerCategories
  const prevLastCategoryId =
    prevTrackerCategories[prevTrackerCategories.length - 1]?.id || 0

  await dispatch(
    createCategory({
      products: [],
      category: categoryName,
      isPrivate: isPrivateCategory,
      stayOnCurrentTab: true
    })
  )

  const { trackerCategories } = getState().globalData
  const lastCategoryId =
    trackerCategories[trackerCategories.length - 1]?.id || 0

  if (lastCategoryId !== prevLastCategoryId) {
    // new category was created
    // move products to new category
    dispatch(
      batchCategorizeProductTracks({
        category_name: categoryName,
        category_id: lastCategoryId
      })
    )
  }
}

/**
 *
 * Check All Tracks Actions
 *
 */

export const toggleCheckProductTrackById = productId => (
  dispatch,
  getState
) => {
  const { tracker } = getState()
  const category = visibleCategory(tracker)

  const product = category.products.find(prod => prod.id === productId)

  if (product) {
    dispatch({
      type: type.TOGGLE_CHECK_PRODUCT_TRACK,
      payload: product
    })
  }
}

export const toggleCheckProductTrack = product => dispatch => {
  dispatch({
    type: type.TOGGLE_CHECK_PRODUCT_TRACK,
    payload: product
  })
}

export const toggleCheckAllProductTracks = () => dispatch => {
  dispatch({
    type: type.TOGGLE_CHECK_ALL_PRODUCT_TRACKS
  })
}

export const resetCheckedProducts = () => dispatch => {
  dispatch({
    type: type.RESET_CHECKED_PRODUCTS
  })
}

export const selectChartType = (chartType, id) => async dispatch => {
  dispatch({
    type: type.SELECT_CHART_TYPE,
    payload: {
      chartType,
      id
    }
  })
}

/**
 *
 * Product Track Notes
 *
 */

export const setTrackForTrackerNotes = product => dispatch => {
  dispatch({
    type: type.SET_ACTIVE_TRACK_SIDECARD,
    payload: product
  })
}

export const setSelectedTrackerNote = note => (dispatch, getState) => {
  const currentSelectedNote = getState().tracker.notes.selectedNote

  if (currentSelectedNote.id !== note.id) {
    dispatch({
      type: type.SET_SELECTED_TRACKER_NOTE,
      payload: note
    })
  }
}

export const updateTrackerNoteField = (id, props) => dispatch => {
  dispatch({
    type: type.UPDATE_TRACKER_NOTE_FIELD,
    payload: {
      id,
      props
    }
  })
}

export const getTrackerNotes = () => async (dispatch, getState) => {
  const { product_track_id, sampleProduct } = getState().tracker.notes.product

  if (sampleProduct) {
    return
  }

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'loadNotes'
  })

  const res = await Api.getTrackerNotes({
    product_track_id
  })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_LOAD_TRACKER_NOTES,
      payload: res.data
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )
  }

  dispatch({
    type: type.RESET_TRACKER_ACTION,
    payload: 'loadNotes'
  })
}

export const createTrackerNote = tracker_note => async (dispatch, getState) => {
  const { product_track_id, sampleProduct } = getState().tracker.notes.product

  if (sampleProduct) {
    return dispatch(
      createNotification({
        message: 'Add a product to your tracker to start creating notes!',
        level: 'info',
        title: 'Tracker Notice:'
      })
    )
  }

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'saveNote'
  })

  const res = await Api.createTrackerNote({
    product_track_id,
    tracker_note
  })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_LOAD_TRACKER_NOTES,
      payload: res.data
    })

    dispatch({
      type: type.COMPLETED_TRACKER_ACTION,
      payload: 'saveNote'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_TRACKER_ACTION,
        payload: 'saveNote'
      })
    }, 5000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )
    dispatch({
      type: type.RESET_TRACKER_ACTION,
      payload: 'saveNote'
    })
  }
}

export const updateTrackerNote = tracker_note => async (dispatch, getState) => {
  const {
    product: { product_track_id, sampleProduct },
    selectedNote
  } = getState().tracker.notes

  if (sampleProduct) {
    return dispatch(
      createNotification({
        message: 'Add a product to your tracker to start creating notes!',
        level: 'info',
        title: 'Tracker Notice:'
      })
    )
  }

  if (!tracker_note.subject || isEmpty(tracker_note.subject)) {
    tracker_note.subject = truncate(textFromHtml(tracker_note.body), {
      length: 50
    })
  }

  if (!selectedNote.id) {
    return dispatch(createTrackerNote(tracker_note))
  }

  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'saveNote'
  })

  const res = await Api.updateTrackerNote({
    product_track_id,
    tracker_note
  })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_LOAD_TRACKER_NOTES,
      payload: res.data
    })

    dispatch({
      type: type.COMPLETED_TRACKER_ACTION,
      payload: 'saveNote'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_TRACKER_ACTION,
        payload: 'saveNote'
      })
    }, 5000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )
    dispatch({
      type: type.RESET_TRACKER_ACTION,
      payload: 'saveNote'
    })
  }
}

export const deleteTrackerNote = () => async (dispatch, getState) => {
  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'deleteNote'
  })

  const {
    product: { product_track_id },
    selectedNote
  } = getState().tracker.notes

  const res = await Api.deleteTrackerNote({
    product_track_id,
    tracker_note: selectedNote
  })

  if (res.ok) {
    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Tracker Note:'
      })
    )

    dispatch({
      type: type.COMPLETED_LOAD_TRACKER_NOTES,
      payload: res.data
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Tracker Error:'
      })
    )
  }

  dispatch({
    type: type.RESET_TRACKER_ACTION,
    payload: 'deleteNote'
  })
}

/*
 *
 *  Recommended products actions
 *
 */
export const resetRecommendedProducts = () => dispatch => {
  // reset the recommended products
  dispatch({
    type: type.SET_TRACKER_DATA,
    payload: {
      recommendedProducts: []
    }
  })
}

export const resetLoadRecommended = () => async dispatch => {
  // reset the recommended products loading status
  dispatch({
    type: type.RESET_TRACKER_ACTION,
    payload: 'loadRecommended'
  })
}

export const getRecommendedProducts = (
  signal,
  asins = [],
  countryOverride = null,
  categoryOverride = null
) => async (dispatch, getState) => {
  dispatch({
    type: type.PENDING_TRACKER_ACTION,
    payload: 'loadRecommended'
  })

  const {
    tracker,
    globalData: { modal }
  } = getState()
  const category = categoryOverride || visibleCategory(tracker)
  const searchQuery = searchQueryForRecommendedProducts(
    category,
    modal,
    tracker,
    asins,
    countryOverride
  )

  const res = await Api.getSimilarProducts(searchQuery, signal)

  if (res.data && res.data.products) {
    dispatch({
      type: type.SET_TRACKER_DATA,
      payload: {
        recommendedProducts: res.data.products
      }
    })
  }

  dispatch({
    type: type.COMPLETED_TRACKER_ACTION,
    payload: 'loadRecommended'
  })
}

export const resetCheckedRecommendedProducts = () => dispatch => {
  dispatch({
    type: type.RESET_CHECKED_RECOMMENDED_PRODUCTS
  })
}

export const toggleCheckRecommendedProduct = product => dispatch => {
  dispatch({
    type: type.TOGGLE_CHECK_RECOMMENDED_PRODUCT,
    payload: product
  })
}

export const sortRecommendedProducts = ({
  column,
  direction
}) => async dispatch => {
  dispatch({
    type: type.SORT_RECOMMENDED_PRODUCTS,
    column,
    direction: direction === 'asc' ? 'desc' : 'asc'
  })
}
